
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';

	@Component({
		name: 'LibraryFilterOptions',
		components: {
			FilterInput,
			IconCrown,
		},
	})
	export default class LibraryFilterOptions extends Vue {
		@Prop({ default: null }) isProActive: boolean;
		@Prop({ default: 'right' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';
		IconCrown = IconCrown;

		filterOptions = [
			{
				name: this.$t('common_elements.filter.price.free_paid'),
				value: null,
			},
			{
				name: this.$t('common_elements.filter.price.free'),
				value: false,
			},
			{
				name: this.$t('common_elements.filter.price.paid'),
				icon: this.IconCrown,
				value: true,
			},
		];

		get activeOption() {
			const filterOptions = this.filterOptions;
			return filterOptions.find((option) => option.value === this.isProActive) || filterOptions[0];
		}

		handleChangeFilter(value: boolean | null) {
			this.$emit('onFilterChange', value);
		}
	}
