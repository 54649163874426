
	import IconInfo from '@pixcap/ui-library/components/Icons/IconInfo.vue';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { LibraryDesignItemType, LibraryMockupItemType } from '@/constants/pixcapassets.constants';

	@Component({
		name: 'ItemOpenAction',
		components: { IconInfo },
	})
	export default class ItemOpenAction extends Vue {
		@Prop() slug: string;
		@Prop() type: string;

		get isMockup() {
			return LibraryMockupItemType.includes(this.type);
		}

		get isTemplate() {
			return LibraryDesignItemType.includes(this.type);
		}

		handleOpenItem(e: MouseEvent) {
			if (e.ctrlKey) return;
			e.preventDefault();
			e.stopPropagation();
			const currentItemIndex = window.history.state?.currentItemIndex == null ? -1 : window.history.state?.currentItemIndex - 1;
			window.history.pushState(
				{ currentItemIndex },
				'',
				`${window.location.origin}/${this.isMockup ? 'mockup' : this.isTemplate ? 'template' : 'item'}/${this.slug}`
			);
			if (this.isMockup || this.isTemplate) LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, this.slug);
			else LibraryMutations.setSelectedLibraryItemSlug(this.$store, this.slug);
		}
	}
